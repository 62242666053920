import React, { useEffect, useRef, useState } from 'react';
import {
  Badge,
  Button,
  Container,
  Nav,
  Navbar,
  Row,
  Col,
  Dropdown,
  Image,
  Placeholder
} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';
import { useAppContext } from '../../contexts/App';
import { useAccountContext } from '../../contexts/Account';
import { useOrderContext } from '../../contexts/Order';
import FontAwesomeIcon from '../../components/FontAwesomeIcon';
import MobileMenuSidebar from '../MobileMenuSidebar';
import HeaderSearch from '../../components/HeaderSearch';
import CryptoService from '../../system/Crypto';
import InitAccountStateData from '../../data/InitAccountStateData';
import Api from '../../Api';

import styles from './styles.module.scss';

const MainNav = () => {
  const { orderState } = useOrderContext();
  const location = useLocation();
  const { appState, dispatchApp } = useAppContext();
  const hasLoaded = useRef(false);
  const { accountState, dispatchAccount, isAuthenticated } = useAccountContext();
  const isTablet = useMediaQuery('(max-width: 991px)');
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [useMobileMenu, setUseMobileMenu] = useState(false);
  const crypto = new CryptoService();

  useEffect(() => {
    if (hasLoaded.current) return;
    hasLoaded.current = true;

    Api.Category.getMenuCategories().then((response) => {
      if ('success' === response.status) {

        appState.setMenu(response.data);

        dispatchApp({ ...appState });
      }
    }).catch((e) => {
      console.log(e);
    });
  }, [appState, dispatchApp]);

  useEffect(() => {
    if (isTablet === useMobileMenu) return;

    setUseMobileMenu(isTablet);
  }, [isTablet, useMobileMenu]);

  const onLogout = () => {
    dispatchAccount({ ...InitAccountStateData });
  };

  const openFacebook = () => {
    window.open('https://www.facebook.com/WholesalePackaging/');
  };

  const openLogin = () => {
    onCloseMobileMenu();
    appState.setDialogState('login', true);
    dispatchApp({ ...appState });
  };

  const openRegister = () => {
    onCloseMobileMenu();
    appState.setDialogState('register', true);
    dispatchApp({ ...appState });
  };

  const onOpenMobileMenu = () => {
    setOpenMobileMenu(true);
  };
  const onCloseMobileMenu = () => {
    setOpenMobileMenu(false);
  };

  const onCall = () => {
    location.href = 'tel:+442083866960';
  };

  return <>
    {openMobileMenu ? <MobileMenuSidebar
      categories={appState.menu}
      openSideBar={openMobileMenu}
      onCloseSideBar={onCloseMobileMenu}
      openLogin={openLogin}
      openRegister={openRegister}
    /> : ''}
    <Navbar
      id={'header'}
      key={'main-nav'}
      expand={true}
      sticky="top"
      className={styles.navbar + ' pt-0 pb-0 bg-light ' + (!useMobileMenu ? 'd-none d-lg-block' : 'd-block d-lg-none') }
    >
      <div className={'primary'}>
        <Container fluid={'xxl'} className={'py-1'}>
          <Row className={'w-100 mx-0 flex-fill'}>
            <Col sm={4} md={6} className={'d-none d-sm-flex align-items-center'}>
              <span className={'text-white text-uppercase fs-9 me-2'}>Follow us</span>
              <Button onClick={() => openFacebook()} size={'sm'}>
                <FontAwesomeIcon type={'brand'} icon={'facebook'} />
              </Button>
            </Col>
            <Col xs={6} className={'d-sm-none'}>
              <Link to={'/'} className={'text-white text-uppercase bolder fs-9 me-2'}>Wholesale Packaging</Link>
            </Col>
            <Col xs={6} sm={8} md={6} className={'text-end'}>
              <Button onClick={() => onCall()} size={'sm'} className={'light text-white'}>
                <FontAwesomeIcon icon={'phone me-2'}/>
                <span><b>Call</b></span>
                <Badge bg={'tertiary'} className="text-primary fs-9 ms-2 d-none d-sm-inline-block">
                  +44(0)208 386 6960
                </Badge>
              </Button>
              <Dropdown className={'ms-3 d-inline-block d-md-none'}>
                <Dropdown.Toggle size={'sm'} variant={'outline-light'} className={'py-1'}>
                  {isAuthenticated ? <Image
                    src={`https://www.gravatar.com/avatar/${crypto.md5(accountState.user.email)}?s=100&d=mp`}
                    className={'d-inline-block w-22px me-2'}
                    rounded
                  /> : <FontAwesomeIcon icon={'user'} className={'me-2'} />}
                  <span className={'me-1 d-none d-sm-inline-block'}>Account</span>
                </Dropdown.Toggle>
                <Dropdown.Menu align={'end'}>
                  {isAuthenticated ? <>
                    <Dropdown.Item as={Link} to={'/account'} className={'fs-7'}>
                      <FontAwesomeIcon icon={'tachometer-alt'} className={'me-2'} />
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Divider className={'border-bottom'} />
                    <Dropdown.Item as={Link} to={'/account/orders'} className={'d-flex fs-7'}>
                      <FontAwesomeIcon icon={'box-alt'} className={'me-2 align-self-center'} />
                      My Orders
                      <Badge
                        bg={isAuthenticated && 0 < accountState.user.orders.length ? 'primary' : 'grey'}
                        className={'ms-auto align-self-center'}
                      >
                        {isAuthenticated ? accountState.user.orders.length : ''}
                      </Badge>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={'/account/wishlist'} className={'d-flex fs-7'}>
                      <FontAwesomeIcon icon={'book-heart'} className={'me-2 align-self-center'} />
                      My Wishlist
                      <Badge
                        bg={isAuthenticated && 0 < accountState.user.wishlist.length ? 'primary' : 'grey'}
                        className={'ms-auto align-self-center'}
                      >
                        {isAuthenticated ? accountState.user.wishlist.length : ''}
                      </Badge>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={'/account/notifications'} className={'d-flex fs-7'}>
                      <FontAwesomeIcon icon={'bell'} className={'me-2 align-self-center'} />
                      Notifications
                      <Badge
                        bg={isAuthenticated && 0 < accountState.user.notifications ? 'primary' : 'grey'}
                        className={'ms-auto align-self-center'}
                      >
                        {isAuthenticated ? accountState.user.notifications : ''}
                      </Badge>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={'/account/addresses'} className={'fs-7'}>
                      <FontAwesomeIcon icon={'address-card'} className={'me-2'} />
                      Manage Addresses
                    </Dropdown.Item>
                    <Dropdown.Divider className={'border-bottom'} />
                    <Dropdown.Item onClick={onLogout} className={'fs-7'}>
                      <FontAwesomeIcon icon={'sign-out'} className={'me-2'} />
                      Logout
                    </Dropdown.Item>
                  </> : <>
                    <Dropdown.Item onClick={() => openLogin()} className={'fs-7'}>
                      <FontAwesomeIcon icon={'sign-in'} className={'me-2'} />
                      Login
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => openRegister()} className={'fs-7'}>
                      <FontAwesomeIcon icon={'edit'} className={'me-2'} />
                      Create account
                    </Dropdown.Item>
                  </>}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Container>
      </div>
      <Container fluid={'xxl'} className={'my-2'}>
        <Row className={'w-100 flex-fill align-items-center'}>
          <Col xs={2} sm={6} lg={4} xl={4} className={'text-center text-md-start'}>
            <Link to={'/'}>
              <Image src={process.env.REACT_APP_HOST_CDN + '/images/icon.png'} height={50} alt="Wholesale Packaging" className={'d-block d-sm-none'} />
              <Image src={process.env.REACT_APP_HOST_CDN + '/images/logo.png'} height={40} alt="Wholesale Packaging" className={'d-none d-sm-block d-md-none'} />
              <Image src={process.env.REACT_APP_HOST_CDN + '/images/logo.png'} height={50} alt="Wholesale Packaging" className={'d-none d-md-block d-xl-none'} />
              <Image src={process.env.REACT_APP_HOST_CDN + '/images/logo.png'} height={64} alt="Wholesale Packaging" className={'d-none d-xl-block'} />
            </Link>
          </Col>
          <Col lg={4} xl={5} className={'d-none d-lg-flex'}>
            <HeaderSearch />
          </Col>
          <Col xs={10} sm={6} lg={4} xl={3} className={'align-items-center'}>
            <Nav className={'justify-content-end'}>
              <Nav.Item className={'fs-9 text-center black-text d-block d-lg-none'}>
                <Button as={Link} to={'/search'} size={'sm'} variant="light" className={'position-relative fs-9'}>
                  <span className={'d-block h-28px'}>
                    <FontAwesomeIcon icon={'search'} className={'text-secondary fs-3'} />
                  </span>
                  Search
                </Button>
              </Nav.Item>
              <Nav.Item className={'fs-9  text-center black-text'}>
                <Button as={Link} to={isAuthenticated ? '/account/notifications' : '/login'} size={'sm'} variant="light"
                        className={'position-relative fs-9'}>
                  <Badge bg={isAuthenticated && 0 < accountState?.user?.notifications ? 'danger' : 'secondary'}
                         className={'position-absolute mt-2 ms-2'}>
                    {isAuthenticated && accountState?.user?.notifications ? accountState?.user?.notifications : 0}
                  </Badge>
                  <span className={'d-block h-28px'}>
                    <FontAwesomeIcon icon={'bell'} className={'text-secondary fs-3 d-block'}/>
                  </span>
                  Notifications
                </Button>
              </Nav.Item>
              <Nav.Item className={'fs-9 text-center black-text d-none d-md-block'}>
                <Button as={Link} to={'/c/clearance-lines'} size={'sm'} variant="light"
                        className={'position-relative fs-9'}>
                  <span className={'d-block h-28px'}>
                    <FontAwesomeIcon icon={'percentage'} className={'text-danger fs-3 d-block'}/>
                  </span>
                  Clearance
                </Button>
              </Nav.Item>
              <Dropdown className={'d-none d-md-block align-self-center'}>
                <Dropdown.Toggle size={'sm'} variant="light" className={'fs-9 black-text align-items-center'}>
                  {isAuthenticated ? <Image
                    src={`https://www.gravatar.com/avatar/${crypto.md5(accountState.user.email)}?s=100&d=mp`}
                    className={'d-block h-28px mx-auto'}
                    roundedCircle
                  /> : <span className={'d-block h-28px'}>
                    <FontAwesomeIcon icon={'user-circle'} className={'text-secondary fs-3 d-block'}/>
                  </span>}
                  Account
                </Dropdown.Toggle>
                <Dropdown.Menu align={'end'}>
                  {isAuthenticated ? <>
                    <Dropdown.Item as={Link} to={'/account'} className={'fs-7'}>
                      <FontAwesomeIcon icon={'tachometer-alt'} className={'me-2'} />
                      Dashboard
                    </Dropdown.Item>
                    <Dropdown.Divider className={'border-bottom'} />
                    <Dropdown.Item as={Link} to={'/account/orders'} className={'d-flex fs-7'}>
                      <FontAwesomeIcon icon={'box-alt'} className={'me-2 align-self-center'} />
                      My Orders
                      <Badge
                        bg={isAuthenticated && 0 < accountState.user.orders.length ? 'primary' : 'grey'}
                        className={'ms-auto align-self-center'}
                      >
                        {isAuthenticated ? accountState.user.orders.length : ''}
                      </Badge>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={'/account/wishlist'} className={'d-flex fs-7'}>
                      <FontAwesomeIcon icon={'book-heart'} className={'me-2 align-self-center'} />
                      My Wishlist
                      <Badge
                        bg={isAuthenticated && 0 < accountState.user.wishlist.length ? 'primary' : 'grey'}
                        className={'ms-auto align-self-center'}
                      >
                        {isAuthenticated ? accountState.user.wishlist.length : ''}
                      </Badge>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={'/account/notifications'} className={'d-flex fs-7'}>
                      <FontAwesomeIcon icon={'bell'} className={'me-2 align-self-center'} />
                      Notifications
                      <Badge
                        bg={isAuthenticated && 0 < accountState.user.notifications ? 'primary' : 'grey'}
                        className={'ms-auto align-self-center'}
                      >
                        {isAuthenticated ? accountState.user.notifications : ''}
                      </Badge>
                    </Dropdown.Item>
                    <Dropdown.Item as={Link} to={'/account/addresses'} className={'fs-7'}>
                      <FontAwesomeIcon icon={'address-card'} className={'me-2'} />
                      Manage Addresses
                    </Dropdown.Item>
                    <Dropdown.Divider className={'border-bottom'} />
                    <Dropdown.Item onClick={onLogout} className={'fs-7'}>
                      <FontAwesomeIcon icon={'sign-out'} className={'me-2'} />
                      Logout
                    </Dropdown.Item>
                  </> : <>
                    <Dropdown.Item onClick={() => openLogin()} className={'fs-7'}>
                      <FontAwesomeIcon icon={'sign-in'} className={'me-2'} />
                      Login
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => openRegister()} className={'fs-7'}>
                      <FontAwesomeIcon icon={'edit'} className={'me-2'} />
                      Create account
                    </Dropdown.Item>
                  </>}
                </Dropdown.Menu>
              </Dropdown>
              <Nav.Item>
                <Button as={Link} to={'/cart'} size={'sm'} variant="light" className={'position-relative fs-9'}>
                  <span className={'d-block h-28px'}>
                    <FontAwesomeIcon icon={'shopping-cart'} className={'text-secondary fs-3 d-block'}/>
                  </span>
                  <Badge bg={0 < orderState?.items?.length ? 'danger' : 'secondary'}
                         className={'position-absolute mt-1 ms-4'}>
                    {orderState?.items?.length ? orderState?.items?.reduce((a, v) => a = a + v.quantity, 0) : 0}
                  </Badge>
                  £{((orderState.total) / 100).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </Button>
              </Nav.Item>
              <Nav.Item onClick={() => onOpenMobileMenu()} className={'fs-9 text-center black-text ms-4 ms-sm-5 d-md-none'}>
                <Button size={'sm'} variant="light" className={'position-relative fs-9'}>
                  <FontAwesomeIcon icon={'bars'} className={'text-secondary fs-3 d-block'} />
                  Menu
                </Button>
              </Nav.Item>
            </Nav>
          </Col>
        </Row>
      </Container>
      <Container fluid={'xxl'} className={'d-none d-md-flex py-2'}>
        <Nav className={`${0 < appState.menu.length ? 'flex-wrap' : ''} position-relative flex-fill justify-content-xl-evenly`} itemScope={true} itemType="http://schema.org/SiteNavigationElement">
          {0 < appState.menu.length ? appState.menu.map((parent, pIdx) => <Nav.Item key={`header_parent_category_${pIdx}`} className={'dropdown-fullwidth'}>
            {0 < parent.categories.length ? <Dropdown navbar={true} autoClose={true}>
              <Dropdown.Toggle variant="light" size={'sm'}>
                {parent.name}
              </Dropdown.Toggle>
              <Dropdown.Menu show={false} className={'bg-light p-2 mt-5 mt-xl-3'}>
                <div className={'ms-2 bolder fs-5'}>
                  <Row>
                    <Col className={'align-self-end'}>
                      {parent.name}
                    </Col>
                    <Col className={'text-end'}>
                      <Dropdown.Item as={'div'} className={'p-0 mt-3 me-2 d-inline'}>
                        <Button as={Link} variant={'outline-dark'} to={`/c/${parent.slug}`} size={'sm'} className={'btn btn-sm btn-outline-dark'} role={'button'}>
                          View all
                          <FontAwesomeIcon icon={'chevron-right'} className={'ms-2'} />
                        </Button>
                      </Dropdown.Item>
                    </Col>
                  </Row>
                </div>
                <hr className={'border-bottom grey-border-bottom border-bottom-lighten-1 mb-1'} />
                <Row>
                  {parent.categories.map((category, cIdx) => 'clearance-lines' !== category.slug ? <Col key={`header_sub_category_${pIdx}_${cIdx}`} xs={12} md={6} lg={4} xl={3}>
                    <Dropdown.Item as={'div'} className={'p-1 mt-2'} active={location.pathname === `/c/${category.slug}`}>
                      <Button
                        as={Link}
                        to={`/c/${category.slug}`}
                        size={'sm'}
                        variant={'light'}
                        className={'fs-8 w-100 text-white text-start text-truncate'}
                      >
                        <Row>
                          <Col xs={4} className={styles.imgHolder}>
                            <div
                              className={`${styles.img} ${styles.profileImage}`}
                              style={{backgroundImage: `url(${process.env.REACT_APP_HOST_CDN + '/catalogue/category/' + category.image})`}}
                            >
                            </div>
                          </Col>
                          <Col xs={8}>
                            <div className={'fs-6 text-primary text-truncate mb-1'}>
                              {category.name}
                            </div>
                            <div className={'fs-9 text-dark h-40px text-wrap mb-3'}>
                              {category.metaDescription ? <>
                                {category.metaDescription.substring(0,75)}...
                              </> : ''}
                            </div>
                            <span className={'text-truncate text-dark'}>
                              <FontAwesomeIcon icon={'chevron-right'} className={'me-2'}/>
                              View all products
                            </span>
                          </Col>
                        </Row>
                      </Button>
                    </Dropdown.Item>
                  </Col> : '')}
                </Row>
              </Dropdown.Menu>
            </Dropdown> : <Button as={Link} to={`/c/${parent.slug}`} variant="light" size={'sm'}>
              {parent.name}
            </Button>}
          </Nav.Item>) : <>
            {[...Array(10)].map((i) => <>
              <Nav.Item key={`header_placeholders_${i}`} className={'w-100 me-2'}>
                <Placeholder bg={'light'} animation={'glow'} size={'sm'}>
                  <Placeholder.Button size={'sm'} variant={'light'} className={'w-100'}/>
                </Placeholder>
              </Nav.Item>
            </>)}
          </>}
        </Nav>
      </Container>
    </Navbar>
  </>;
};

export default MainNav;
